<template>
  <div class="login-box">
    <div class="login">
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="state.username"
            name="用户名"
            label="用户名"
            placeholder="用户名"
            :rules="[{ required: true, message: '请填写用户名' }]"
          />
          <van-field
            v-model="state.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button
            v-if="loadingFlag"
            loading
            round
            block
            type="primary"
            loading-text="加载中..."
          />
          <van-button v-else round block type="primary" native-type="submit">
            提交
          </van-button>
        </div>
      </van-form>
	  <div class="beian-a-box">
		  <a class="beian-a" href="https://beian.miit.gov.cn/" target="_blank" v-if="url_text == 'www.zhongaming.cn'">粤ICP备2023014551号-2</a>
		  <a class="beian-a" href="https://beian.miit.gov.cn/" target="_blank" v-if="url_text == 'www.zhongaming.com'">粤ICP备2023014551号-1</a>
	  </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import { Button, Form, Field, CellGroup } from "vant";
import { Toast } from "vant";
import { useRouter } from "vue-router";
import { setPermissions, setToken,setCampusId,setCampusName } from "../../lib/unit";
import api from "../../api/login";
export default {
  name: "Login",
  components: {
    "van-button": Button,
    "van-form": Form,
    "van-field": Field,
    "van-cell-group": CellGroup,
  },
  setup() {
    const router = useRouter();
    const loadingFlag = ref(false);
    const state = reactive({
      username: "",
      password: "",
    });
    const url_text = ref('')
    url_text.value = window.location.host
    console.log(url_text)
    const onSubmit = async (values) => {
      // console.log("submit", values);
      loadingFlag.value = true;
      const data = { username: values["用户名"], password: values["密码"] };
      try {
        const res = await api.login(data);
        if (res.code == 200) {
          setToken(res.data.token);
          setPermissions(res.data.permissions);
          setCampusId(res.data.campus_id);
          setCampusName(res.data.campus_name)

          loadingFlag.value = false;
          router.push({ path: "/home", replace: true });
        } else if (res.code == 201) {
          loadingFlag.value = false;
          Toast(res.msg);
        }
      } catch (error) {
        // console.log(error);
        loadingFlag.value = false;
        Toast("登录出错");
      }
    };
    return {
      // property
      state,
      loadingFlag,
      url_text,
      //  method
      onSubmit,
    };
  },
};
</script>
<style scoped lang="less">
.login-box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/image/bg-login.jpg");
  background-size: 100% 100%;
  .beian-a-box{
  	  width: 100%;
  	  height: 30px;
  	  font-size: 12px;
  	  line-height: 30px;
  	  text-align: center;
  	  position: absolute;
  	  left: 0;
  	  bottom: 0;
  	  a{
  		  color: #fff;
  	  }
  }
}

::v-deep .van-button,
::v-deep .van-cell {
  font-size: 40px !important;
}
</style>
